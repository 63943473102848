import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import store from "@/vuex/store.js";

import http from "@/axios/api.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/ele-common.css";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import "@/rem";

// Vue.prototype.baseURL = "https://test.xiaoguoai.cn";
// Vue.prototype.baseURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.baseURL = 'https://xiaoguobx.com/'
Vue.prototype.$bus = new Vue();

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$http = http;

// import { Message } from "element-ui";
// Vue.use(Message);
// Vue.prototype.$message = Message;

router.beforeEach((to, from, next) => {
  if (to.name == "newsinfo" && to.query && to.query.title) {
    document.title = "澄澄" + "-" + to.query.title;
    next();
    return;
  }
  if (to.meta.title) {
    document.title = "澄澄" + "-" + to.meta.title;
  } else {
    document.title = "澄澄";
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
