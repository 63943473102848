import Vue from 'vue';
import Vuex from 'vuex';
import { getCookie } from '@/utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    userNo:null,
    themeType:'dayMode',
    tabBarHeight:0,
    user:{
      mobile:"18299999999",
      biNo:"",
      key:"",
      openId:"",
      userAgent:"",
      token:"",
    },
    custType:'',
    loginState:{
      isLogin:false,
    }
  },
  mutations: {
    SET_TOKEN(state, params) {
      state.token = params.Token;
      state.userNo = params.userNo;
    },
    SET_CUSTTYPE(state, params) {
      state.custType = params.custType;
      
    },
    SET_THEMETYPE(state, themeType) {
      state.themeType = themeType;
    },
    SET_TabBARHEIGHT(state, h) {
      state.tabBarHeight = h;
    },
    SET_LOGINSTATE(state, loginStatus) {
      let loginState = { ...state.loginState }
      state.loginState = { ...loginState, ...loginStatus }
    },
    SAVE_USER(state,params){
      state.user={...state.user,...params}
    }
    

  },
  actions: {
    setToken({ commit }, params) {
     
      commit('SET_TOKEN',params );
    },
    setCustType({ commit }, params) {
     
      commit('SET_CUSTTYPE',params );
    },
    setThemeType({ commit }, themeType) {
      commit('SET_THEMETYPE', themeType);
    },
    setTabBarHeight({ commit },h){
      commit('SET_TabBARHEIGHT', h);
    },
    setLoginState({ commit }, state) {// 登录状态
      commit('SET_LOGINSTATE', state);
    },
    saveUser({ commit }, params){
      commit('SAVE_USER', params);
    }
  },
  getters:{
    user:state=>state.user,
    isLogin(state) {
      if (state.loginState.isLogin) {
        return true;
      } else {
        return false;
      }
    },
    getToken:state=>{
      return state.token;
    },
    getCustType:state=>{
      return state.custType;
    }
    
  }

})