<template>
  <div id="app-m">
    <div class="headers">
      <div class="header-1">
        <div>
          <img src="@/assets/firstpage/logo-of.svg" alt="" />
          <!-- <img src="@/assets/firstpage/图层 1495@2x.png" alt="" v-if="this.showme" /> -->
        </div>
        <div v-if="!this.showList" @click="changeShowList">
          <img style="width:33px" src="../assets/mobile/home/形状 1.png" />
        </div>
        <div v-if="this.showList" @click="changeShowList">
          <img style="width:33px" src="../assets/mobile/home/形状 1 拷贝.png" />
        </div>
      </div>
      <div class="boxshadow"></div>
    </div>
    <div class="header-1-2" v-show="showList">
      <!-- <div class="header-1-3-item">
        <div class="student" @click="login(0)">我是学生</div>
        <div class="teacher" @click="login(1)">我是老师</div>
      </div> -->
      <div class="header-1-4-item" v-for="(item, index) in headerList" :key="item.id" @click="go(index)"
        :class="{ active: currentfirstitem == index }">
        <div>
          <img :src="require('../assets/mobile/home/图层' + item.img)" />
          {{ item.title }}
        </div>
        <div>＞</div>
      </div>
    </div>
    <div v-if="showList" class="bg"></div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="line8" v-show="btn">
      <div class="line8-ctx">
        <!-- <div class="line8-1">
          <div class="line8-1-1">
            <img src="@/assets/firstpage/图层 1495 拷贝.png" alt="" v-if="!this.showme" />
            <img src="@/assets/firstpage/图层 1495 拷贝@2x.png" alt="" v-if="this.showme" />
          </div>
        </div> -->
        <div class="line8-2">
          <div class="title">关于我们</div>
          <div class="content">
            湖南果实智能科技有限公司以“AI赋能教育，数据驱动学习、自主智能系统”为目标，依托于浙大专家团队联合开发的澄澄3.0自适应学习系统，公司立足于全国前沿教育，致力于基于未来科技教育的现代化解决方案，多项技术和产品获得国家专利，并取得了国家教育部、信息产业部等多部门的资质认证。
          </div>
        </div>
        <div class="line8-3">
          <div class="title">联系方式</div>
          <div class="phone">
            <img src="@/assets/firstpage/图层 43.png" alt="" />
            <!-- <img src="@/assets/firstpage/图层 43@2x.png" alt="" v-if="this.showme" /> -->
            <span>电话：</span>
            <span>15616705276</span>
          </div>
          <div class="email">
            <img src="@/assets/firstpage/图层 44.png" alt="" />
            <!-- <img src="@/assets/firstpage/图层 44@2x.png" alt="" v-if="this.showme" /> -->
            <span>邮箱：</span>
            <span>guoshiedu@qq.com</span>
          </div>
          <div class="address">
            <img src="@/assets/firstpage/图层 45.png" alt="" />
            <!-- <img src="@/assets/firstpage/图层 45@2x.png" alt="" v-if="this.showme" /> -->
            <span>地址：</span>
            <span>长沙市芙蓉区古汉国际广场1栋11楼</span>
          </div>
        </div>
        <div class="line8-1-2">
          <img src="@/assets/firstpage/erweima.jpg" alt="" />
          <p>关注公众号</p>
        </div>
      </div>
    </div>
    <div class="line9" v-show="btn">
      <div class="line9-ctx">
        Copyright © 2003-2021 <br />澄澄.All Rights Reserved 湘ICP备2023007847号-2
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showList: false, //导航列表显示 | 隐藏 == true | false
      headerList: [], //header列表
      currentfirstitem: 0, //header当前项
      // showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      activeClass: true, //样式切换
      // showTop: true, //滚动到100距离隐藏顶部
      showLogin: 1, //登录框
      isShadow: false,
      btn: true,
    };
  },
  watch: {
    //考虑浏览器前进后退
    $route: {
      handler(to) {
        const compArr = ["/", "/help", "/news", "/join", "/info", "/newsinfo"];
        let idx = compArr.findIndex((item) => {
          return item === to.path;
        });
        if (idx !== 0) {
          this.activeClass = false;
        } else {
          this.activeClass = true;
        }
        this.currentfirstitem = idx === 6 ? 3 : idx;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getRoute();
    this.getData();
  },
  mounted() {
    // 手机端滑动页面,关闭导航列表
    window.addEventListener("touchmove", () => {
      this.showList = false;
    });
    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrollTop )
      const routerArr = ["/", "/help", "/news", "/join", "/info"];
      if (scrollTop > 600 && this.$route.path === "/") {
        this.activeClass = false;
        this.isShadow = true;
      }
      if (scrollTop < 600 && this.$route.path === "/") {
        this.activeClass = true;
        this.isShadow = false;
      }
      if (scrollTop > 377 && this.$route.path === "/news") {
        this.isShadow = true;
      }
      if (scrollTop < 377 && this.$route.path === "/news") {
        this.isShadow = false;
      }
    });
  },
  methods: {
    changeShowList() {
      this.showList = !this.showList;
    },
    getRoute() {
      let url = window.location.href;
      let lastName = url.split("/");

      if (lastName[lastName.length - 1] === "share") {
        this.btn = false;
      } else {
        this.btn = true;
      }
      console.log();
    },
    //获取数据
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      // let a = currentWidth > 1920 ? '@2x' : ''
      // this.showme = currentWidth > 1920 ? 0 : 0;
      this.headerList = [
        {
          id: 0,
          title: "首页",
          img: "11.png",
        },
        {
          id: 1,
          title: "使用帮助",
          img: "12.png",
        },
        {
          id: 2,
          title: "新闻中心",
          img: "13.png",
        },
        {
          id: 3,
          title: "招商加盟",
          img: "14.png",
        },
        {
          id: 4,
          title: "了解企业",
          img: "15.png",
        },
      ];
    },
    //路由
    go(param) {
      this.showList = false;
      const routerArr = ["/", "/help", "/news", "/join", "/info"];
      if (routerArr[param] === this.$route.path) return;

      // 当点击新闻页的时候，提醒分页跳转到首页
      if (param === 3) {
        this.$bus.$emit("back");
      }

      if (param !== 0) {
        this.activeClass = false;
      } else {
        this.activeClass = true;
      }
      this.$router.push({
        path: routerArr[param],
      });
      this.currentfirstitem = param;
    },

    //登录
    login(p) {
      //
      if (!p) {
        let url = window.location.origin
        if (url.match(RegExp(/xiaoguobx/g))) {
          url = window.location.origin + "/student/";
        } else {
          url = window.location.origin + "/s/";
        }
        window.location.href = url;
        // window.open(`${this.baseURL}/student/#/login`);
      } else {
        let url = window.location.origin
        if (url.match(RegExp(/xiaoguobx/g))) {
          url = window.location.origin + "/teacher/";
        } else {
          url = window.location.origin + "/t/";
        }
        window.location.href = url;
        // window.open(`${this.baseURL}/teacher/#/`);
      }
    },
  },
};
</script>

<style scoped>
@import "../style_mobile/Header.css";

.header-shadow {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}
</style>
