import { shareCoinToCust } from "./server/api";

export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i].trim();
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}
export function setCookie(name, value) {
    var exp = new Date();
    exp.setTime(exp.getTime() + 60 * 60 * 1000);
    document.cookie =
        name +
        "=" +
        escape(value) +
        ";expires=" +
        exp.toGMTString() +
        ";path=/";
}

export function delCookie(name){//为了删除指定名称的cookie，可以将其过期时间设定为一个过去的时间 
    var date = new Date(); 
    date.setTime(date.getTime() - 10000); 
    document.cookie = name + "=a; expires=" + date.toGMTString(); 
}


export function createFormData(params){
    const formData = new FormData();
    if(params){
        for( let k in params){
             formData.append(k,params[k])
        }
   }

   return formData
}



