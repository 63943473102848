<template>
  <div id="Header">
    <div
      v-show="btn"
      :class="[
        activeClass ? 'header' : 'headers',
        isShadow ? 'header-shadow' : '',
      ]"
    >
      <div :class="[activeClass ? 'header-1' : 'header-2']" v-if="showTop">
        <div class="header-1-1" v-if="activeClass">
          <!-- <img src="@/assets/firstpage/图层 1495 拷贝.png" alt="" v-if="!this.showme" /> -->
          <img
            src="@/assets/firstpage/组 3@2x.png"
            style="height: 80px; width: 445px; padding-top: 5px"
            v-if="!this.showme"
          />
        </div>

        <!-- <div class="header-1-1" v-if="!activeClass">
          <img src="@/assets/firstpage/logo-02.svg" style="height:66px; width: 172px; padding-top: 5px;"
            v-if="!this.showme" />
        </div> -->
        <div class="header-1-2">
          <div
            v-for="(item, index) in headerList"
            :key="item.id"
            @click="go(index)"
            :class="{ active: currentfirstitem == index }"
          >
            <span v-if="index !== 1">
              {{ item.title }}
            </span>
            <div v-else ref="download">
              <div @click="download(1)">浏览器下载</div>
            </div>
            <!-- <div v-else @mouseover="showDownload" @mouseleave="hideDownload"
              :class="{ active: isShowDownload ? true : false }">
              下载
              <div ref="download"
                style="background:#fff; transition: all 0.5s; border-radius:5px; overflow:hidden; height:0px">
                <div
                  style="width:100%; height:50px; line-height:50px; color:#000; border-bottom:1px solid rgba(0,0,0,0.05);"
                  @click="download(1)">
                  浏览器下载
                </div>
                <div style="width:100%; height:50px; line-height:50px; color:#000;" @click="download(2)">
                  Scratch下载
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div
          ref="login"
          class="header-1-3"
          @mouseover="mouseoverLogin"
          @mouseleave="mouseleaveLogin"
        >
            <div class="student" @click="login(0)">监督系统</div>
            <div class="teacher" @click="login(1)">学生系统</div>
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <div>
      <img src="@/assets/firstpage/组底部3.png" alt="" class="line10"/>
    </div>
    <!-- <div class="line3">
      <div class="line3-ctx">
        <div class="line3-3">
            <img src="@/assets/firstpage/组底部1.png" alt=""/>
        </div>
       
      </div>
     
    </div> -->
    <!-- Copyright © 2003-2024 MOMAAI.CN All Rights Reserved -->
    <!-- 湘ICP备2023007847号-2 POWERED BY 技术支持 -->
    <div class="line9" v-show="btn">
      <div class="line9-ctx">
        
        <a class="a_targt" target="_blank" href="http://beian.miit.gov.cn"
          ></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      headerList: [], //header列表
      currentfirstitem: 0, //header当前项
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      activeClass: true, //样式切换
      showTop: true, //滚动到100距离隐藏顶部
      showLogin: 1, //登录框
      isShadow: false,
      btn: true,
      isShowDownload: false,
      testHeight:''
    };
  },
  watch: {
    //考虑浏览器前进后退
    $route: {
      handler(to) {
        if (to.path === "/scratchdownload") {
          this.isShowDownload = true;
        } else {
          this.isShowDownload = false;
        }
        const compArr = [
          "/",
          "/browserdownload",
          "/help",
          "/news",
          "/join",
          "/info",
          "/newsinfo",
        ];
        let idx = compArr.findIndex((item) => {
          return item === to.path;
        });
        if (idx !== 0) {
          this.activeClass = true;
        } else {
          this.activeClass = true;
        }
        this.currentfirstitem = idx === 6 ? 3 : idx;
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getRoute();
    this.getData();
  },
  mounted() {
    let that = this;

    // setTimeout(() => {
    //   let doms = document.getElementsByClassName("line10");
    //   that.testHeight = doms[0].height + "px";
    // }, 100);

    // window.onresize = () => {
    //   return (() => {
    //     let doms = document.getElementsByClassName("line10");
    //     that.testHeight = doms[0].height + "px";
    //   })();
    // };

    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const routerArr = [
        "/",
        "/browserdownload",
        "/help",
        "/news",
        "/join",
        "/info",
      ];
      if (scrollTop > 600 && this.$route.path === "/") {
        this.activeClass = false;
        this.isShadow = true;
      }
      if (scrollTop < 600 && this.$route.path === "/") {
        this.activeClass = true;
        this.isShadow = false;
      }
      if (scrollTop > 377 && this.$route.path === "/news") {
        this.isShadow = true;
      }
      if (scrollTop < 377 && this.$route.path === "/news") {
        this.isShadow = false;
      }
    });
  },
  methods: {
    getRoute() {
      let url = window.location.href;
      let lastName = url.split("/");

      // 如果打开的为share页面,则关闭页头页脚,添加title,meta
      if (lastName[lastName.length - 1] === "share") {
        this.btn = false;
      } else {
        this.btn = true;
      }
    },
    //获取数据
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      // let a = currentWidth > 1920 ? '@2x' : ''
      this.showme = currentWidth > 1920 ? 0 : 0;
      this.headerList = [
        {
          id: 0,
          title: "首页",
        },
        {
          id: 1,
          title: "浏览器下载",
        },
        // {
        //     id:1,
        //     title:'客户端下载'
        // },
        {
          id: 2,
          title: "申请试课",
        },
        {
          id: 3,
          title: "加盟咨询",
        },
        {
          id: 4,
          title: "联系我们",
        },
      ];
    },
    //路由
    go(param) {
      if (param !== 1) {
        const routerArr = ["/", "", "/help", "/news", "/join", "/info"];
        if (routerArr[param] === this.$route.path) return;

        // 当点击新闻页的时候，提醒分页跳转到首页
        if (param === 3) {
          this.$bus.$emit("back");
        }

        if (param !== 0) {
          this.activeClass = false;
        } else {
          this.activeClass = true;
        }
        this.$router.push({
          path: routerArr[param],
        });
        this.currentfirstitem = param;
      } else {
        // console.log("okkkkkkkkkkk", param);
      }
    },
    download(value) {
      if (this.$route.path !== "/browserdownload" && value === 1) {
        this.$router.push("/browserdownload");
      }
      if (this.$route.path !== "/scratchdownload" && value === 2) {
        this.$router.push("/scratchdownload");
      }
    },
    mouseoverLogin() {
      this.showLogin = 1;
      this.$refs.login.style.height = "177px";
    },
    mouseleaveLogin() {
      this.showLogin = 0;
      this.$refs.login.style.height = "77px";
    },
    showDownload() {
      this.$refs.download[0].style.height = "100px";
    },
    hideDownload() {
      this.$refs.download[0].style.height = "0px";
    },
    //登录
    login(p) {
      //
      if (!p) {
        let url = window.location.origin+ "/t/";
        window.location.href = url;
        // window.open(`${this.baseURL}/student/#/login`);
      } else {
        let url = window.location.origin  + "/s/";
        window.location.href = url;
        // window.open(`${this.baseURL}/teacher/#/`);
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/Header.styl';

.header-shadow {
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
}

.a_targt {
  color: #949494;
  text-decoration: none;
}

.header-1-2 div {
  width: 100px;
  text-align: center;
}

.header-1-2>div:nth-child(1) {
  position: relative;
  right: -25px;
}

.txt_big {
  display: flex;
  flex-direction: column;
  font-size: 5px;
  width: 250px;
  height: 100px;
}

.txt_small {
  height: 10px;
}

.line10 {
  width: 100%;
  height: auto;
  box-sizing: border-box
}
</style>
