import http from "../axios/api";
import { createFormData } from "@/utils.js";

// const _host =  process.env.NODE_ENV=="development" ?  '/api':  'http://xiaoguoai.cn/api'; //'http://qo.bzhuan888.com'
// console.log(_host)
// http://xiaoguoai.cn/api/ht/login?username='主页'&password='123456'&access_token=

export async function getNotice(data) {
  return http.post(`/system/notice/search`, data);
}
// 查询
export function getHelp(data) {
  return http.post(`/help/findHelp`, data);
}

//新闻列表
export function getNewsList(data) {
  return http.post(`/news/getNewsList`, data);
}
//新闻内容的阅读量
export function getCount(data) {
  return http.post(`/news/newsCount/${data.id}`);
}

//获取验证码
export function getCode(param) {
  return http.post(`/wechat/applet/send/code`, param);
}
//发送客户信息
export function remberRemark(param) {
  return http.post(`wechat/applet/remberRemark`, param);
}
//
export function consultQuestion(param) {
  return http.post(`admin/addIntentionalCustomer`, param);
}
//
