"use strict";

import axios from "axios";
import qs from "qs";
import store from "@/vuex/store";
import Vue from "vue";
// import router from "@/router";
import { delCookie } from "@/utils";

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
}
axios.interceptors.request.use(
  (config) => {
    //config.headers.Token = getCookie('token');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.resolve(error.response);
  }
);

function errorState(response) {
  store.commit("UPDATE_LOADING", false); //隐藏loading
  //console.log(response)
  // 如果http状态码正常，则直接返回数据
  if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
    return response;
    // 如果不需要除了data之外的数据，可以直接 return response.data
  } else {
    Vue.prototype.$msg.alert.show({
      title: "提示",
      content: "网络异常",
    });
  }
}

function successState(res) {
  store.commit("UPDATE_LOADING", false); //隐藏loading
  //统一判断后端返回的错误码
  if (res.data.errCode == "000002") {
    Vue.prototype.$msg.alert.show({
      title: "提示",
      content: res.data.errDesc || "网络异常",
      onShow() {},
      onHide() {
        console.log("确定");
      },
    });
  } else if (res.data.errCode != "000002" && res.data.errCode != "000000") {
    Vue.prototype.$msg.alert.show({
      title: "提示",
      content: res.data.errDesc || "网络异常",
      onShow() {},
      onHide() {
        console.log("确定");
      },
    });
  }
}
// var AddressURL = "http://192.168.119.55:8083"; // 接口公共地址部分-变量//公共地址全局变量
var AddressURL = process.env.VUE_APP_BASE_API;
// var AddressURL = "https://xiaoguobx.com/";
// var AddressURL = "https://test.xiaoguoai.cn:8083/";

function checkStatus(response) {
  try {
    const { data, code } = response;
    if (response.code == 102 || response.code == 103) {
      delCookie("token");
      store.dispatch("setLoginState", {
        isLogin: false,
      });
      /*  router.push({name:"advisoryList"}); */
      return {
        status: "102",
        msg: "您的账户登录已失效，请重新登录",
      };
    }
    if (data && (data.code == 102 || data.code == 103)) {
      delCookie("token");
      store.dispatch("setLoginState", {
        isLogin: false,
      });
      /*  router.push({name:"advisoryList"}); */
      return {
        status: "102",
        msg: "您的账户登录已失效，请重新登录",
      };
    }
  } catch (err) {
    return {};
  }
  // loading
  // 如果http状态码正常，则直接返回数据
  if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
    return response.data;
    // 如果不需要除了data之外的数据，可以直接 return response.data
  }

  if (response && response.status === 401) {
    alert("登陆已过期,请重新登陆!");
  }
  // 异常状态下，把错误信息返回去
  return {
    status: -404,
    msg: "网络异常",
  };
}

function checkCode(res) {
  // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
  if (res.status === -404) {
    // alert(res.msg)
    console.log(res.msg);
  }
  if (res.data && !res.data.success) {
    // alert(res.data.error_msg)
  }
  return res;
}

export default {
  post(
    url,
    data,
    option = {
      hasToken: true,
      isStoreToken: true,
      header: {},
    }
  ) {
    let headers = option.header;
    let TOKEN =
      option.token || option.isStoreToken ? store.getters.user.token : getCookie("token") || "";
    url; //+'?access_token='+sessionStorage.getItem('token')
    if (TOKEN && option.hasToken) {
      headers["TOKEN"] = TOKEN;
    }

    return axios({
      method: "POST",
      baseURL: AddressURL,
      url,
      data,
      // timeout: 10000,
      headers,
    })
      .then((response) => {
        return checkStatus(response);
      })
      .catch((res) => {
        return checkCode(res);
      });
  },

  get(
    url,
    params,
    option = {
      hasToken: true,
      isStoreToken: true,
      header: {},
    }
  ) {
    let headers = option.header;
    let TOKEN =
      option.token || option.isStoreToken ? store.getters.user.token : getCookie("token") || "";
    url; //+'?access_token='+sessionStorage.getItem('token')
    if (TOKEN) {
      headers["TOKEN"] = TOKEN;
    }
    return axios({
      method: "get",
      baseURL: AddressURL,
      url,
      params, // get 请求时带的参数
      timeout: 10000000,
      headers,
    })
      .then((response) => {
        return checkStatus(response);
      })
      .catch((res) => {
        return checkCode(res);
      });
  },

  put(url, params) {
    url = url + "?access_token=" + sessionStorage.getItem("token");
    return axios({
      method: "put",
      baseURL: AddressURL,
      url,
      data: qs.stringify(params),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => {
        return checkStatus(response);
      })
      .catch((res) => {
        return checkCode(res);
      });
  },
  del(url, params) {
    url = url + "?access_token=" + sessionStorage.getItem("token");
    return axios({
      method: "delete",
      baseURL: AddressURL,
      url,
      data: qs.stringify(params),
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    })
      .then((response) => {
        return checkStatus(response);
      })
      .catch((res) => {
        return checkCode(res);
      });
  },
};
