<template>
  <div id="home">
    <!-- 头部 -->
    <!-- <div class="header" id="a1"></div> -->
    <div class="big_box">
      <div>
        <div>
          <!-- <div class="img1-2">以人工智能技术为驱动的教育科技公司</div> -->
          <el-carousel :height="testHeight" :autoplay="true">
            <el-carousel-item v-for="item in imgList" :key="item.id">
              <img ref="imgs" :src="item.idView" class="image" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- 锚点键 -->
    <!-- <div class="cur-href">
      <div
        class="item"
        v-for="(item, index) in alist"
        :key="index"
        :class="{ active: currentA === index }"
        @click="goHref(index)"
      >
        <a :href="item.ctx"></a>
      </div>
    </div> -->
    <div class="backtop" v-show="this.backTop" @click="back">
      <img src="@/assets/firstpage/返回顶部.png" alt="" v-if="!this.showme" />
      <img src="@/assets/firstpage/返回顶部@2x.png" alt="" v-if="this.showme" />
    </div>

    <!-- 证书显示 -->
    <div class="sign_logo">
      <a href="https://xyt.xcc.cn/getpcInfo?sn=1648586267769569280&language=CN&certType=8&url=*.momaai.cn"
        target="_blank" style="position: relative;display: inline-block;height: 38px;">
        <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed
          src="https://program.xinchacha.com/web/1648586267769569280=*.momaai.cn.svg" width="103" height="38"
          type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/" />
      </a>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 墨马新增
      imgList: [],
      // 墨马新增
      aa: 0,
      current3Item: 0, //line3当前选择项
      line3List: [], //line3列表
      currentWidth: 1920, //当前页面宽度
      line4bg: "", //line4背景图
      line4List: [], //line4列表
      current4Item: 0, //line4当前选择项
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      line7List: [], //line7列表
      backTop: false, //返回顶部
      currentA: 0, //a锚点当前
      alist: [
        {
          id: 0,
          ctx: "#a1",
        },
        {
          id: 1,
          ctx: "#a2",
        },
        {
          id: 2,
          ctx: "#a3",
        },
        {
          id: 3,
          ctx: "#a4",
        },
        {
          id: 4,
          ctx: "#a5",
        },
        {
          id: 5,
          ctx: "#a6",
        },
        {
          id: 6,
          ctx: "#a7",
        },
        {
          id: 7,
          ctx: "#a8",
        },
      ], ///a锚点列表

      testHeight: "", //走马灯高度
    };
  },
  created() {
    //获取line3,line4List
    this.getData();
  },
  mounted() {
    let that = this;

    setTimeout(() => {
      let doms = document.getElementsByClassName("image");
      that.testHeight = doms[0].height + "px";
    }, 100);

    window.onresize = () => {
      return (() => {
        let doms = document.getElementsByClassName("image");
        that.testHeight = doms[0].height + "px";
      })();
    };
    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 2500) {
        this.backTop = true;
      } else {
        this.backTop = false;
      }
      // const arr = [10,300,900,1600,2250,3055]
      switch (true) {
        case 0 <= scrollTop && scrollTop < 10:
          this.currentA = 0;
          break;
        case 10 <= scrollTop && scrollTop < 300:
          this.currentA = 1;
          break;
        case 300 <= scrollTop && scrollTop < 900:
          this.currentA = 2;
          break;
        case 900 <= scrollTop && scrollTop < 1600:
          this.currentA = 3;
          break;
        case 1600 <= scrollTop && scrollTop < 2250:
          this.currentA = 4;
          break;
        case 2250 <= scrollTop && scrollTop < 3055:
          this.currentA = 5;
          break;
        case 3055 <= scrollTop && scrollTop < 3552:
          this.currentA = 6;
          break;
        case 3552 <= scrollTop:
          this.currentA = 7;
          break;
      }
    });
  },
  methods: {
    //获取line3,line4List
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      let a = currentWidth > 1920 ? "" : "";
      this.showme = currentWidth > 1920 ? 0 : 0;
      this.aa = a;
      this.imgList = [
        { id: 0, idView: require("@/static/images/首页轮播图1.jpg") },
        { id: 1, idView: require("@/static/images/首页轮播图2.jpg") },
        { id: 2, idView: require("@/static/images/首页轮播图3.jpg") },
        { id: 3, idView: require("@/static/images/首页轮播图4.png") },
      ];
      this.line4bg = require(`../assets/firstpage/组 126${a}(2).png`);
      this.line4List = [
        {
          id: 0,
          title: "高效智能辅导",
          content:
            "基于大数据分析、人工智能算法、知识图谱的澄澄AI老师,同时通过线下互动方式，帮助学生全面解决知识漏洞。",
        },
        {
          id: 1,
          title: "全面检测知识薄弱点情况",
          content:
            "课后综合测试，对比课前测评，综合检测学生知识薄弱点带你掌握变化情况，以便实时调整下一步学习内容。",
        },
        {
          id: 2,
          title: "智能检测学习水平",
          content:
            "人工智能技术全面扫描学科知识点，准确探测知识漏洞并产出可视化报告，360度解读学生知识点掌握情况。",
        },
        {
          id: 3,
          title: "个性化学习路径",
          content:
            "根据检测后的掌握情况，为学生智能匹配学习的内容，量身定制个性化的学习方案，每个学生都有一份属于自己的学习路径。",
        },
        {
          id: 4,
          title: "纳米级知识点练习",
          content:
            "把知识点拆成纳米级知识点，然后针对每个知识点进行专项练习，填补知识漏洞以构建学生完整的知识网。 ",
        },
      ];
      this.line7List = [
        {
          id: 0,
          src: require(`../assets/firstpage/组 57${a}.png`),
          title: "14%+",
          content: "班级分数平均提升",
        },
        {
          id: 1,
          src: require(`../assets/firstpage/组 58${a}.png`),
          title: "30%",
          content: "学生年级排名平均提升",
        },
        {
          id: 2,
          src: require(`../assets/firstpage/组 59${a}.png`),
          title: "100%",
          content: "学生的进步率提升",
        },
        {
          id: 3,
          src: require(`../assets/firstpage/组 60${a}.png`),
          title: "教学效率大幅提升",
          content: "每天节约老师2~3小时批改时间针对作业报告暴露问题专项讲解",
        },
        {
          id: 4,
          src: require(`../assets/firstpage/组 126${a}(4).png`),
          title: "学习成绩显著提高",
          content: "使用澄澄平台后班级学生平均成绩明显提升",
        },
        {
          id: 5,
          src: require(`../assets/firstpage/组 126${a}(5).png`),
          title: "认真程度与效果成正比",
          content: "使用学生名次提升与作业正确率呈显著相关性",
        },
      ];
    },
    //去锚点
    goHref(p) {
      this.currentA = p;
    },
    //鼠标移入切换line3投影
    mouseoverLine3(param) {
      this.current3Item = param;
    },
    //鼠标移入切换line4背景色
    mouseoverLine4(param) {
      this.current4Item = param;
    },
    //回到顶部
    back() {
      let h, backtopspeed, timer;
      timer = setInterval(() => {
        h = document.documentElement.scrollTop || document.body.scrollTop;
        backtopspeed = Math.floor(-h / 8);
        if (h > 0) {
          document.documentElement.scrollTop = document.body.scrollTop =
            h + backtopspeed;
        } else {
          clearInterval(timer);
        }
      }, 20);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../style/home.styl';
</style>

<style>
.el-carousel {
  width: 100%;
  height: auto;
  margin-top: 77px;
  margin-bottom: -6px;
}
.el-carousel__container {
  height: auto;
}
.image {
  width: 100%;
  height: auto;
}

.test_main {
  font-size: 30px;
}
.test_next {
  height: 35px;
  font-size: 20px;
  font-family: Adobe Heiti Std;
  color: #777878;
}
.sign_logo {
  position: absolute;
  right: 2px;
  top: 40%;
  z-index: 2
}

</style>
