import Vue from "vue";
import VueRouter from "vue-router";

import home from "@/components/home.vue";
import home_m from "@/components_mobile/home.vue";
import Header from "@/components/Header.vue";
import Header_m from "@/components_mobile/Header.vue";

Vue.use(VueRouter);

const isMobile = /android|ipad|iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(
  window.navigator.userAgent.toLowerCase()
);

let routes;

if (!isMobile) {
  // pc端路由
  routes = [
    {
      path: "/",
      component: Header,
      children: [
        {
          path: "/",
          name: "index",
          component: home,
        },
        {
          path: "/browserdownload",
          name: "browserdownload",
          component: () => import("@/components/browserdownload.vue"),
        },
        {
          path: "/scratchdownload",
          name: "scratchdownload",
          component: () => import("@/components/scratchdownload.vue"),
        },
        {
          path: "/clientdownload",
          name: "clientdownload",
          component: () => import("@/components/clientdownload.vue"),
        },
        {
          path: "/help",
          name: "help",
          component: () => import("@/components/help.vue"),
          meta: {
            title: "使用帮助",
          },
        },
        {
          path: "/news",
          name: "news",
          component: () => import("@/components/news.vue"),
          meta: {
            title: "新闻中心",
          },
        },
        {
          path: "/newsinfo",
          name: "newsinfo",
          component: () => import("@/components/newsinfo.vue"),
          meta: {
            title: "",
          },
        },
        {
          path: "/join",
          name: "join",
          component: () => import("@/components/join.vue"),
          meta: {
            title: "招商加盟",
          },
        },
        {
          path: "/share",
          name: "share",
          component: () => import("@/components/share.vue"),
        },
        {
          path: "/info",
          name: "info",
          component: () => import("@/components/info.vue"),
          meta: {
            title: "了解企业",
          },
        },
        {
          path: "*",
          name: "404",
          component: () => import("@/components/a.vue"),
          meta: {
            title: "404",
          },
        },
      ],
    },
  ];
} else {
  // 移动端路由
  routes = [
    {
      path: "/",
      component: Header_m,
      children: [
        {
          path: "/",
          component: home_m,
        },
        {
          path: "/news",
          component: () => import("@/components_mobile/news.vue"),
        },
        {
          path: "/newsinfo",
          component: () => import("@/components_mobile/newsinfo.vue"),
        },
        {
          path: "/help",
          component: () => import("@/components_mobile/help.vue"),
        },
        {
          path: "/join",
          component: () => import("@/components_mobile/join.vue"),
        },
        {
          path: "/info",
          component: () => import("@/components_mobile/info.vue"),
        },
      ],
    },
  ];
}

const router = new VueRouter({
  mode: "hash",
  routes,
});

// 路由滚动到顶部
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  next();
});

export default router;
