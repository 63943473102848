<template>
  <div id="home-m">
    <!-- 头部 -->
    <!-- <div class="header" id="a1"></div> -->
    <div class="line2" id="a2">
      <div class="line2-ctx">
        <div class="img1">
          <div class="img1-1">
            <!-- 晓果伴学AI自适应学习系统 -->
            品牌介绍
          </div>
          <!-- <div class="img1-2">
                    以人工智能技术为驱动的教育科技公司
                </div> -->
          <div class="img1-3">
            <div class="img1-3-left">
              <img
                :src="require(`../assets/firstpage/class-room-home.png`)"
                alt=""
              />
            </div>
            <div class="img1-3-right">
              <div class="right-3">
                晓果伴学AI学习系统，是湖南果实智能科技有限公司自主研发，以高级算法为核心的自适应学习引擎，拥有高度知识产权，并获得国家专利认证。
              </div>
              <div class="right-4">
                大数据是未来教育发展的基础，未来的在线教育机构首先应是大数据机构，因此湖南果实智能科技有限公司打造面向未来的教学系统——晓果伴学AI一对一学习系统。晓果伴学AI学习系统以艾宾浩斯遗忘曲线、神经网络技术、自适应技术、脑科学技术、大数据技术等智能算法为基础，通过人机互动的测评、过程分析等，不断地给学生绘制学习画像，确定学生的掌握状态、学习能力等级，精准侦测不同学生知识漏洞，查漏补缺，线上AI老师给孩子一对一量身定做教育方案，并一对一实施教育。
              </div>
              <div class="right-4">
                晓果智能AI老师，是第二代人工智能自适应学习系统，通过数据分析将知识点进行拆分，并利用AI老师精确的智能画像，对每一个学生的薄弱环节进行针对性辅导，使学生不会把时间浪费在已熟练掌握的知识点上，从而提高学生的学习效率。
              </div>
            </div>
          </div>
          <div class="img1-4">
            <div class="img1-4-1">
              <div class="top">9+</div>
              <span class="bottom"> 技术沉淀 </span>
            </div>
            <div class="img1-4-1">
              <div class="top">500+</div>
              <span class="bottom"> 签约机构 </span>
            </div>
            <div class="img1-4-1">
              <div class="top">1600+</div>
              <span class="bottom"> 学校数 </span>
            </div>
            <div class="img1-4-1 img1-4-4">
              <div class="top">1500万+</div>
              <span class="bottom"> 学员使用数 </span>
            </div>
            <div class="img1-4-1 img1-4-5">
              <div class="top">全科</div>
              <span class="bottom"> 覆盖学科 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line3" id="a3">
      <div class="line3-ctx">
        <div class="line3-1">人工智能教育优势</div>
        <div class="line3-2">人工智能创新模式 引领教育新航标</div>
        <div class="line3-3">
          <div
            v-for="(item, index) in line3List"
            :key="item.id"
            class="line3-3-item"
            :class="{ active: current3Item == index, last: index == 3 }"
            @mouseover="mouseoverLine3(index)"
          >
            <div class="img">
              <img class="line3-3-1" :src="item.scr" alt="" />
            </div>
            <div class="line3-3-2">
              {{ item.title }}
            </div>
            <div class="line3-3-3">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line4" id="a4">
      <div class="line4-1">晓果伴学提分王提分逻辑</div>
      <div class="line4-2">
        测、学、练、测、辅无缝衔接，形成学习紧密闭环，垂直检测，无限循环
      </div>
      <div class="line4-3">
        <div
          class="logicList"
          v-for="(item, index) in line4List"
          :key="item.id"
          :class="{ active: current4Item == index }"
          @mouseover="mouseoverLine4(index)"
        >
          <div class="title">
            {{ item.title }}
          </div>
          <div class="content">
            {{ item.content }}
          </div>
          <div class="backFont">
            {{ item.backFont }}
          </div>
        </div>
      </div>
    </div>
    <div class="line5" id="a5">
      <div class="line5-1">专业的教研</div>
      <div class="line5-2">全学段、全学科、全智能</div>
      <div class="line5-3">
        <img src="../assets/mobile/home/组 129.png" alt="" />
      </div>
      <div class="line5-4">
        <div class="line5-4-1">
          <div>
            <img src="../assets/mobile/home/组 43.png" alt="" />
          </div>
          <div>
            <p class="line5-4-2">教研权威专业</p>
            <p class="line5-4-3">全国公立小、初、高名师，辅导下的教研体系</p>
          </div>
        </div>
        <div class="segment"></div>
        <div class="line5-4-1">
          <div>
            <img src="../assets/mobile/home/组 44.png" alt="" />
          </div>
          <div>
            <p class="line5-4-2">教材全面覆盖</p>
            <p class="line5-4-3">本地化题库，实现精准，高效的教与学</p>
          </div>
        </div>
        <div class="segment"></div>
        <div class="line5-4-1">
          <div>
            <img src="../assets/mobile/home/组 45.png" alt="" />
          </div>
          <div>
            <p class="line5-4-2">题库持续更新</p>
            <p class="line5-4-3">
              海量、结构化（四级知识体系）持续更新的动态资源
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="line6" id="a6">
      <div class="line6-1">我们的优势</div>
      <div class="line6-2">
        覆盖教学全场景的教学辅助工具和资源平台，强大的数据挖掘和分析技术，有效提升教育信息化水平和教育决策能力，
        弥补教育信息化闭环中家长参与度不足的问题，促进全体学生实现符合个性化成长规律的智慧发展。
      </div>
      <div class="line6-3">
        <div class="line6-3-1">
          <div class="title">教师·以学定教</div>
          <div class="content">
            <!-- <div class="content-1"> -->
            <img
              src="@/assets/firstpage/组 51 拷贝 3.png"
              alt=""
              v-if="!this.showme"
            />
            <!-- </div> -->
            <div class="content-2">
              <div class="right-title">海量题库</div>
              <div class="right-content">
                独有全科知识点体系知识点与题目精准对应
              </div>
            </div>
          </div>
          <div class="content">
            <!-- <div class="content-1"> -->
            <img
              src="@/assets/firstpage/组 51 拷贝 2.png"
              alt=""
              v-if="!this.showme"
            />
            <!-- </div> -->
            <div class="content-2">
              <div class="right-title">云端批改</div>
              <div class="right-content">
                覆盖全部题型 <br />
                真正实现减负增效
              </div>
            </div>
          </div>
          <div class="content">
            <!-- <div class="content-1"> -->
            <img
              src="@/assets/firstpage/组 51 拷贝 4.png"
              alt=""
              v-if="!this.showme"
            />
            <!-- </div> -->
            <div class="content-2">
              <div class="right-title">学习报告</div>
              <div class="right-content">
                作业、小测试、考试行为结果数据全量收集
              </div>
            </div>
          </div>
        </div>
        <div class="line6-3-2">
          <div class="title">学生·个性化学习</div>
          <div class="content">
            <!-- <div class="content-1"> -->
            <img
              src="@/assets/firstpage/组 51.png"
              alt=""
              v-if="!this.showme"
            />
            <!-- </div> -->
            <div class="content-2">
              <div class="right-title">精准推送</div>
              <div class="right-content">
                作业数据结合推荐算法，定位薄弱点，自动推送复习题
              </div>
            </div>
          </div>
          <div class="content">
            <!-- <div class="content-1"> -->
            <img
              src="@/assets/firstpage/组 51 拷贝.png"
              alt=""
              v-if="!this.showme"
            />
            <!-- </div> -->
            <div class="content-2">
              <div class="right-title">错题再练</div>
              <div class="right-content">
                自动添加错题至错题本，有效补全知识漏洞
              </div>
            </div>
          </div>
        </div>
        <div class="line6-3-3">
          <div class="title">学生·个性化学习</div>
          <div class="content">
            <!-- <div class="content-1"> -->
            <img
              src="@/assets/firstpage/组 51 拷贝 5.png"
              alt=""
              v-if="!this.showme"
            />
            <!-- </div> -->
            <div class="content-2">
              <div class="right-title">进度追踪</div>
              <div class="right-content">
                随时查看孩子多维度学习报告，获得孩子的学习进度
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="line7" id="a7">
      <div class="line7-1">教学成果</div>
      <div class="line7-2">好效果不只是理论，我们已帮助数十万学生提成绩</div>
      <div class="line7-3">
        <div v-for="item in line7List" :key="item.id" class="line7-3-item">
          <img class="img" :src="item.src" alt="" />
          <div class="title">
            {{ item.title }}
          </div>
          <div class="content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>

    <div class="line6 line8" id="a8">
      <div class="line6-1">校区风采</div>
      <div class="line6-2">因成绩而肯定，因价值而不凡</div>
      <div class="line6-3 campus-list">
        <div class="campus-item">
          <div class="img-box">
            <img src="../assets/image/campus-1.png" alt="" />
          </div>
          <p>福建泉州校区</p>
        </div>
        <div class="campus-item">
          <div class="img-box">
            <img src="../assets/image/campus-4.png" alt="" />
          </div>
          <p>深圳光明区弘乐校区</p>
        </div>
        <div class="campus-item">
          <div class="img-box">
            <img src="../assets/image/campus-3.png" alt="" />
          </div>
          <p>恒大第1001校区</p>
        </div>
        <div class="campus-item">
          <div class="img-box">
            <img src="../assets/image/campus-2.png" alt="" />
          </div>
          <p>深圳龙岗校区</p>
        </div>
      </div>
    </div>
    <!-- 锚点键 -->
    <div class="cur-href">
      <div
        class="item"
        v-for="(item, index) in alist"
        :key="index"
        :class="{ active: currentA === index }"
        @click="goHref(index)"
      >
        <a :href="item.ctx"></a>
      </div>
    </div>

    <!-- <div class="backtop" v-show="this.backTop" @click="back">
      <img src="@/assets/firstpage/返回顶部.png" alt="" v-if="!this.showme" />
      <img src="@/assets/firstpage/返回顶部@2x.png" alt="" v-if="this.showme" />
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aa: 0,
      current3Item: 0, //line3当前选择项
      line3List: [], //line3列表
      currentWidth: 1920, //当前页面宽度
      line4bg: "", //line4背景图
      line4List: [], //line4列表
      current4Item: 0, //line4当前选择项
      showme: 0, //大小图片显示切换 0 | 1 == 小 | 大
      line7List: [], //line7列表
      // backTop: false, //返回顶部
      currentA: 0, //a锚点当前
      alist: [
        {
          id: 0,
          ctx: "#a1",
        },
        {
          id: 1,
          ctx: "#a2",
        },
        {
          id: 2,
          ctx: "#a3",
        },
        {
          id: 3,
          ctx: "#a4",
        },
        {
          id: 4,
          ctx: "#a5",
        },
        {
          id: 5,
          ctx: "#a6",
        },
        {
          id: 6,
          ctx: "#a7",
        },
        {
          id: 7,
          ctx: "#a8",
        },
      ], ///a锚点列表
    };
  },
  created() {
    //获取line3,line4List
    this.getData();
  },
  mounted() {
    //滚动到2500距离出现返回顶部图标
    window.addEventListener("scroll", () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // if (scrollTop > 2500) {
      //   this.backTop = true;
      // } else {
      //   this.backTop = false;
      // }
      // const arr = [10,300,900,1600,2250,3055]
      switch (true) {
        case 0 <= scrollTop && scrollTop < 10:
          this.currentA = 0;
          break;
        case 10 <= scrollTop && scrollTop < 300:
          this.currentA = 1;
          break;
        case 300 <= scrollTop && scrollTop < 900:
          this.currentA = 2;
          break;
        case 900 <= scrollTop && scrollTop < 1600:
          this.currentA = 3;
          break;
        case 1600 <= scrollTop && scrollTop < 2250:
          this.currentA = 4;
          break;
        case 2250 <= scrollTop && scrollTop < 3055:
          this.currentA = 5;
          break;
        case 3055 <= scrollTop && scrollTop < 3552:
          this.currentA = 6;
          break;
        case 3552 <= scrollTop:
          this.currentA = 7;
          break;
      }
    });
  },
  methods: {
    //获取line3,line4List
    getData() {
      const currentWidth = document.documentElement.clientWidth;
      let a = currentWidth > 1920 ? "" : "";
      this.showme = currentWidth > 1920 ? 0 : 0;
      this.aa = a;
      this.line3List = [
        {
          id: 0,
          scr: require(`../assets/firstpage/组 32${a}.png`),
          title: "传统线下培训",
          description:
            "无法全面了解每个学生的学习情况，无法解决针对性教学，招生成本高。",
        },
        {
          id: 1,
          scr: require(`../assets/firstpage/组 34${a}.png`),
          title: "传统线上培训",
          description:
            "解决了区域问题，但是多为录制课程，课程质量层次不齐，无法保证学生吸收程度，无法落地达到提分的最终目的。",
        },
        {
          id: 2,
          scr: require(`../assets/firstpage/组 32${a}(1).png`),
          title: "一对一教学",
          description:
            "可以有效监督教学，但对师资力量要求较高，极大增加投资者的成本。",
        },
        {
          id: 3,
          scr: require(`../assets/firstpage/组 37${a}.png`),
          title: "人工智能教育",
          description:
            "AI+大数据赋能，利用大数据推送针对性学习内容，名师在线、分层教学、人机互动教学质量高。",
        },
      ];
      this.line4bg = require(`../assets/firstpage/组 126${a}(2).png`);
      this.line4List = [
        {
          id: 0,
          title: "智能检测学习水平",
          content:
            "人工智能技术全面扫描学科知识点，准确探测知识漏洞并产出可视化报告，360度解读学生知识点掌握情况。",
          backFont: "测",
        },
        {
          id: 1,
          title: "个性化学习路径",
          content:
            "根据检测后的掌握情况，为学生智能匹配学习的内容，量身定制个性化的学习方案，每个学生都有一份属于自己的学习路径。",
          backFont: "学",
        },
        {
          id: 2,
          title: "纳米级知识点练习",
          content:
            "把知识点拆成纳米级知识点，然后针对每个知识点进行专项练习，填补知识漏洞以构建学生完整的知识网。",
          backFont: "练",
        },
        {
          id: 3,
          title: "全面检测薄弱点掌握情况",
          content:
            "课后综合测试，对比课前测评，综合检测学生知识薄弱点带你掌握变化情况，以便实时调整下一步学习内容。",
          backFont: "测",
        },
        {
          id: 4,
          title: "高效智能辅导 ",
          content:
            "基于大数据分析、人工智能算法、知识图谱的澄澄AI老师,同时通过线下互动方式，帮助学生全面解决知识漏洞。 ",
          backFont: "辅",
        },
      ];
      this.line7List = [
        {
          id: 0,
          src: require(`../assets/firstpage/组 57${a}.png`),
          title: "14%+",
          content: "班级分数平均提升",
        },
        {
          id: 1,
          src: require(`../assets/firstpage/组 58${a}.png`),
          title: "30%",
          content: "学生年级排名平均提升",
        },
        {
          id: 2,
          src: require(`../assets/firstpage/组 59${a}.png`),
          title: "100%",
          content: "学生的进步率提升",
        },
        {
          id: 3,
          src: require(`../assets/firstpage/组 60${a}.png`),
          title: "教学效率大幅提升",
          content: "每天节约老师2~3小时批改时间针对作业报告暴露问题专项讲解",
        },
        {
          id: 4,
          src: require(`../assets/firstpage/组 126${a}(4).png`),
          title: "学习成绩显著提高",
          content: "使用晓果伴学平台后班级学生平均成绩明显提升",
        },
        {
          id: 5,
          src: require(`../assets/firstpage/组 126${a}(5).png`),
          title: "认真程度与效果成正比",
          content: "使用学生名次提升与作业正确率呈显著相关性",
        },
      ];
    },
    //去锚点
    goHref(p) {
      this.currentA = p;
    },
    //鼠标移入切换line3投影
    mouseoverLine3(param) {
      this.current3Item = param;
    },
    //鼠标移入切换line4背景色
    mouseoverLine4(param) {
      this.current4Item = param;
    },
    //回到顶部
    back() {
      let h, backtopspeed, timer;
      timer = setInterval(() => {
        h = document.documentElement.scrollTop || document.body.scrollTop;
        backtopspeed = Math.floor(-h / 8);
        if (h > 0) {
          document.documentElement.scrollTop = document.body.scrollTop =
            h + backtopspeed;
        } else {
          clearInterval(timer);
        }
      }, 20);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../style_mobile/home.css';
</style>
